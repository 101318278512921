// About page (/about)

.markdown {
  font-family: _font(family-heading);
  font-size: 0.9em;
  padding-bottom: 36px;

  p {
    margin: auto;
  }

  h1 {
    font-size: 0.8em !important;
    margin-top: 3em;
  }

  h4 {
    font-size: 0.9em !important;

    // font-family: _font(family-heading);
    font-weight: _font(weight-heading-bold);
  }

  // ul {
  //   font-family: _font(family-heading);
  //   font-size: 0.9em;
  // }
}
