#experience {
  p {
    font-family: _font(family-heading);
    font-size: 0.9em;
  }
}

.jobs-container {
  font-family: _font(family-heading);
  font-size: 0.9em;

  .heading-4 {
    margin: 0 0 0.2em 0;
    font-size: 0.9em;
  }

  // .projects {
  //   margin-bottom: 1em;
  // }

  .projects-container {
    display: flex;
    padding-bottom: 32px;
    padding-top: 8px;

    .sidebar-navigator {
      display: flex;
      flex: 0.35;

      .sidebar-navigator-cell-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        height: 350px;

        .sidebar-cell {
          display: flex;
          flex-grow: 1;
          border-left: 1px solid black;
          border-right: 1px solid black;
          border-top: 1px solid black;

          align-items: center;
          padding: 8px;
          cursor: pointer;

          &:hover {
            background-color: #f4f4f4;
          }
        }
        .sidebar-cell:last-child {
          border-bottom: 1px solid black;
        }

        .active-cell {
          background-color: #aaaa;

          &:hover {
            background-color: #aaaa;
          }
        }
      }
    }

    .content-container {
      display: flex;
      flex: 0.65;
      padding-left: 12px;
    }
  }

  //   .projecttitle {
  // font-weight: _font(weight-heading-bold);
  // font-size: 1em;
  // color: #3c3b3b;
  // font-family: 'Raleway', Helvetica, sans-serif;
  // // font-weight: 600;
  // font-weight: normal;

  // color: _palette(fg-bold);
  // font-family: _font(family-heading);
  // font-weight: _font(weight-heading-bold);
  // font-size: 0.9em;
  // color: #646464;
  // // letter-spacing: _font(kerning-heading);
  // line-height: 1.65;
  // margin: 0 0 (_size(element-margin) * 0.5) 0;
  // text-transform: uppercase;
  //   }
}
