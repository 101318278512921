:root {
  --white: #ffffff;
  --greyLight-1: #f3f4f5;
  --greyLight-2: #e2e3e5;
}

#landing {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0px 100px;

  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .head {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;

      .name {
        font-size: 36px;
        font-family: _font(family);
        margin-bottom: 0;
      }

      .subtitle {
        color: #200305;

        // color: #68252f !important;
      }
    }

    p {
      font-weight: 300;
      line-height: 1.35;
    }

    .iconsContainer {
      display: flex;
      margin-top: 24px;

      a {
        color: #200305;

        &:hover {
          border-bottom-color: transparent;
          color: #68252f !important;

          // &:before {
          // 	color: _palette(accent) !important;
          // }
        }
      }
    }

    hr {
      width: 80px;
      height: 2px;
      margin: 15px 10px 10px 10px;
      //   background-color: #c7666c;
      background-color: #200305 !important;
    }
  }

  .rightContainer {
    .circle {
      display: grid;
      grid-template-rows: 1fr;
      justify-items: center;
      align-items: center;
      padding: 60px 0px 60px 0px;
      float: right;
      // width: 40vw;
      //   margin-right: 10vw;
    }

    .circle__btn {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      width: 300px;
      height: 300px;
      display: flex;
      margin: 0.6rem;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 3.2rem;
      color: var(--primary);
      z-index: 300;
      background: var(--greyLight-1);
      box-shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
        -0.2rem -0.2rem 0.5rem var(--white);
      position: relative;
    }

    .circle__btn.shadow {
      box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
        inset -0.2rem -0.2rem 0.5rem var(--white);
    }

    img {
      width: 320px;
      height: 320px;
      border-radius: 50%;
      padding: 20px;
      z-index: 405;
    }

    .circle__back-1,
    .circle__back-2 {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      filter: blur(1px);
      z-index: 100;
    }

    .circle__back-1 {
      box-shadow: 0.4rem 0.4rem 0.8rem var(--greyLight-2),
        -0.4rem -0.4rem 0.8rem var(--white);
      background: linear-gradient(
        to bottom right,
        var(--greyLight-2) 0%,
        var(--white) 100%
      );
      animation: waves 4s linear infinite;
    }

    .circle__back-2 {
      box-shadow: 0.4rem 0.4rem 0.8rem var(--greyLight-2),
        -0.4rem -0.4rem 0.8rem var(--white);
      animation: waves 4s linear 2s infinite;
    }
  }

  #footer {
    .description {
      text-align: center;
      margin: 0;
    }
    .description-tech {
      text-align: center;
      margin: 0;
      color: #68252f !important;
    }
  }

  /* height: 80vh; */
  /* overflow-x: hidden; */
}

@media (max-width: 1100px) {
  /* On mobile screens (up to 768px), align div2 and div3 column-wise */
  #landing {
    display: flex;
    flex-direction: column;
    align-items: center;

    .leftContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      text-align: center;

      .head {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        .name {
          font-size: 36px;
          font-family: _font(family);
          margin-bottom: 0;
        }

        .subtitle {
          color: #200305;

          // color: #68252f !important;
        }
      }

      p {
        font-weight: 300;
        line-height: 1.35;
      }

      .iconsContainer {
        display: flex;
        margin-top: 24px;
        justify-content: center;

        a {
          color: #200305;

          &:hover {
            border-bottom-color: transparent;
            color: #68252f !important;

            // &:before {
            // 	color: _palette(accent) !important;
            // }
          }
        }
      }

      hr {
        width: 80px;
        height: 2px;
        margin: 15px 10px 10px 10px;
        //   background-color: #c7666c;
        background-color: #200305 !important;
      }
    }
  }

  #footer {
    .description {
      width: 100vw;
    }

    .description-tech {
      width: 100vw;
    }
  }
}

@media (max-width: 600px) {
  #landing {
    .leftContainer {
      .iconsContainer {
        width: 372px;
        justify-content: center;
        width: 100vw;
      }
    }
    .rightContainer {
      .circle {
        .circle__back-1 {
          width: 190px;
          height: 190px;
        }

        .circle__back-2 {
          width: 190px;
          height: 190px;
        }
      }
    }
  }

  #footer {
    .description {
      width: 100vw;
    }

    .description-tech {
      width: 100vw;
      margin: 0;
    }
  }
}

@media (max-width: 600px) {
  #landing {
  }
}
// .text {
//   float: left;
//   width: 90vw;
//   margin: auto;
//   margin-right: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

// .intro {
// }

// .socials {
//   display: flex;
//   flex-direction: row;
//   margin: auto;
// }

// hr {
//   width: 120px;
//   text-align: left;
//   margin: 15px 10px 10px 10px;
//   height: 2px;
//   border-width: 0;
//   background-color: #c7666c;
// }

// .socialicon {
//   fill: none;
//   stroke: #c7666c;
//   margin: 5px;
//   margin-bottom: 10px;
//   width: 20px;
//   height: 20px;
//   transition: transform 0.2s;
// }

// .socialicon:hover {
//   transform: translateY(-3px);
//   stroke: #7dcbd8;
// }
// .socials div {
//   height: 100%;
//   margin-top: 6px;
// }
// .socials a {
//   color: black;
//   padding: 0 5px;
// }

// .socials a:hover {
//   transform: translateY(-3px);
// }

// .fill {
//   fill: #b2d5e0;
// }

// .title {
//   color: #c7666c;
//   font-size: 20px;
//   margin-top: 0;
// }

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@media (max-width: 900px) {
  .intro {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .text {
    margin: 0;
  }

  p {
    width: 90vw;
  }

  .socials {
    margin-top: 20px;
    padding-bottom: 7vh;
  }

  hr {
    width: 80px;
    margin: 15px 10px 10px 10px;
  }

  .circle__btn {
    margin: 0;
  }

  .circle {
    width: 80vw;
    padding: 40px 0;
    margin: auto;
  }

  .circle__back-1,
  .circle__back-2 {
    width: 180px;
    height: 180px;
  }
}
