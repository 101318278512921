#education {
  .education {
    .degreeText {
      color: _palette(fg-bold);
      font-family: _font(family-heading);
      font-weight: _font(weight-heading-bold);

      // font-weight: _font(weight-heading-bold);
      letter-spacing: 0.1em;
      line-height: 1.65;
      margin: 0 0 0.4em 0;
      text-transform: uppercase;
      font-size: 0.9em;
    }

    .school {
      text-transform: uppercase;
    }

    ul {
      font-family: _font(family-heading);
      font-size: 0.9em;
    }
  }
}
